<template>
  <div>
    <MyHeader :title="$t('lang.menu_temppatrolquery')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />

    <van-pull-refresh v-model="loading" @refresh="onRefresh">
    <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
      direction="down" @load="onLoad">
      <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.deptName" is-link :to="{
          name: 'TempPatrolDetail',
          params: { data: mData },
        }">
        <template slot="label">
          <span>{{ $t("lang.arriveTime") }}：{{ $Tools.FormatDate1(mData.arriveTime) }}</span><br />
          <span>{{ $t("lang.inspector") }}：{{ mData.inspectorName }}</span><br />
          <span>{{ $t("lang.device") }}：{{ mData.deviceName }}</span>
        </template>
      </van-cell>
    </van-list>
    </van-pull-refresh>
    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->
  </div>
</template>
<style scoped>
.van-icon-checked {
  color: green;
}

.van-icon-warning {
  color: red;
}
</style>

<script>
import MyHeader from "@/components/Header.vue";
import { GetTempPatrolRecords } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      backUrl: ""
      //showHint: true,
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/query/index";
    else
      this.backUrl = "/query/appindex";
    //console.log("mounted");
  },

  activated() {
    //console.log("activated");
    if (this.$route.params.p) {
      this.page = 1;
      this.lstData = [];
      this.Query();
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: {
          title: this.$t("lang.menu_temppatrolquery"),
          f: "TempPatrolQuery",
        },
      });
    },
    onRefresh() {
      this.page = 1;
      this.Query();
    },
    Query() {
      //console.log("query");
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        //this.showHint = true;
        return;
      }
      //console.log(p);
      let that = this;
      that.loading = true;

      GetTempPatrolRecords(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strInspectorIds: p.mUser,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              if (this.page == 1) that.lstData = ret.data;
              else that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },
  },
};
</script>
